<template>
  <div class="auth-body google-auth">

      <SimpleAlert
              class="auth-body__alert"
              @close="closeAlert"
              :color="'green'"
              :type="'success'"
              :text="$t('login_emailVerified.localization_value.value')"
              v-if="openAlert"
      />

      <div class="auth-body__form">
      <div class="auth-form"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate([
          'profile_googleAuthentication',
          'profile_googleAuthenticationTxt',
          'profile_verificationCode',
          'common_submit',
        ])"></span>


          <div class="auth-form__inner">
            <div class="auth-form__title">
            {{$t('profile_googleAuthentication.localization_value.value')}}
            </div>
            <div class="auth-form__subtitle">
            {{$t('profile_googleAuthenticationTxt.localization_value.value')}}
            </div>

            <div class="auth-form__field">
              <WhiteInput
                      v-model="code"
                      v-bind:class="{'ui-no-valid': validation.code}"
                      :error="validation.code"
                      :errorTxt="validationTxt.code"
                      :label="$t('profile_verificationCode.localization_value.value')"
                      @onEnter="submitForm" />
            </div>

            <div class="auth-form__btn">
              <div class="auth-form__btn-login">
                <FullButton
                    @click.native="submitForm"
                    :value="$t('common_submit.localization_value.value')"
                    v-bind:class="{'disabled-btn' : $store.getters.getToFactorCodeBtn}"
                />
              </div>
            </div>

            <!--<div class="authentication__click-here">-->
              <!--To get QR code-->
              <!--<span @click="isModalQRPopup = true" class="site-link">-->
                 <!--Click here-->
            <!--</span>-->
            <!--</div>-->
          </div>



        </div>

      </div>

      <BottomLine/>


  </div>
</template>

<script>
import SimpleAlert from "../../../../../UI/alerts/SimpleAlert/SimpleAlert.vue";
import FullButton from "../../../../../UI/buttons/FullButtons/FullButton.vue";
import WhiteInput from "../../../../../UI/inputs/WhiteInput/WhiteInput.vue";
import BottomLine from "../../../../../coreComponents/BottomLine/BottomLine.vue";
// import GoogleAuthenticationAdmin from "../GoogleAuthenticationAdmin/GoogleAuthenticationAdmin";


export default {
  name: "GoogleAuthenticationUser",
  components: {
    SimpleAlert,
    WhiteInput,
    FullButton,
    BottomLine,
    // GoogleAuthenticationAdmin,
  },

  data() {
    return {

      code: '',
      time: 0,
      resend: false,
      defaultTimeCheck: 180,
      openAlert: false,
      openErrorAlert: false,
      errorForm: false,
      serverError: false,
      requestCodeAgain: false,
      isModalQRPopup: false,

      validation: {
        code: false,
      },

      validationTxt: {
        code: false,
      }
    }
  },

  mounted() {

  },

  methods: {

    closeAlert() {
      this.openAlert = false;
      this.openErrorAlert = true;
    },

    submitForm(){
      this.$store.dispatch('twoFactorGoogleSendCode', {code: this.code}).then((response) => {
        if(this._.has(response, 'response') && response.response.status === this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE) {
          this.serverError = true
          this.validation.code = true
          this.validationTxt.code = response.response.data.errors.code[0]
          return
        }

        /**
         * linkName - name router link in router paths
         */
        let linkName = this.$route.params.linkName
        let params = ''
        if(Object.keys(this.$route.query).length > 0){
          params = '?' + Object.keys(this.$route.query).map(item => {return `${item}=${this.$route.query[item]}`}).join('&')
        }

        if(response.data.data.auth_type === 'email'){
          let user = this.$store.getters.getUserProfile
          user.auth_type = 'email'
          this.$store.commit('setUserProfile', {userProfile: user})
          // this.$router.push(this.$store.getters.GET_PATHS.mainSettingsMyProfile)
        }

        // if(response.data.data.auth_type === 'google') {
        //   this.$router.push(this.$store.getters.GET_PATHS.mainSettingsMyProfile)
        // }

        if(linkName) {
          this.$router.push(this.$store.getters.GET_PATHS[linkName] + params)
        }
      }).catch(error => this.createErrorLog(error))
    }

  },

}

</script>

<style lang="scss">


</style>
