
<template>
  <div class="login-page auth-wrap">

    <div class="auth-head">
      <div class="auth-head__logo">
        <router-link :to="$store.getters.GET_PATHS.login">
          <img src="/img/login-group/login-logo.png" alt="alt">
        </router-link>
      </div>
    </div>

    <router-view></router-view>

  </div>

</template>

<script>

  export default {
    name: "Authentication",

    data() {
      return{

      }
    },

    mounted() {
      this.$store.commit('setHideMenuAndHeader')
    },

    beforeDestroy() {
      this.$store.commit('setShowMenuAndHeader')
    }

  }
</script>

<style scoped>

</style>
